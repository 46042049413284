"use client";

import { ReactNode } from "react";
import {
  Stack,
  Container,
  Box,
  Flex,
  Text,
  Heading,
  SimpleGrid,
} from "@chakra-ui/react";
import { MdRemove } from "react-icons/md";

export default function StatsGridWithImage() {
  return (
    <Box bg={"gray.800"} position={"relative"} id="about-us">
      <Container maxW={"80%"} zIndex={10} position={"relative"}>
        <Stack direction={{ base: "column", lg: "row" }}>
          <Stack
            flex={1}
            color={"gray.400"}
            justify={{ lg: "center" }}
            py={{ base: 4, md: 20 }}
          >
            <Box mb={{ base: 8, md: 20 }}>
              <Text
                fontFamily={"heading"}
                fontWeight={700}
                textTransform={"uppercase"}
                mb={3}
                fontSize={"xl"}
                color={"gray.500"}
                display="flex"
                align="center"
              >
                <span>About us</span>
                <MdRemove
                  style={{
                    color: "white",
                    fontSize: "24px",
                    marginLeft: "1rem",
                  }}
                />
              </Text>
              <Heading
                color={"white"}
                mb={5}
                fontSize={{ base: "3xl", md: "5xl" }}
              >
                Brontel Limited - Telecom Network Solutions
              </Heading>
              <Flex
                fontSize={"xl"}
                flexDir={{ base: "column", md: "row" }}
                color={"gray.400"}
                gap="1.5rem"
              >
                <Box width="100%">
                  {" "}
                  At Brontel Ltd, we are dedicated to transforming the
                  telecommunication landscape through our expertise in Telecom
                  Network Design, Survey and Planning, Optimization,
                  Documentation, and Network Audits.
                </Box>
                <Box>
                  {" "}
                  With a mission to ensure seamless connectivity and unmatched
                  performance, we pride ourselves on delivering innovative
                  solutions tailored to your needs.
                </Box>
              </Flex>
              <br />
              <Text fontSize={"xl"} color={"gray.400"} gap="1.5rem">
                Our core services include the following
              </Text>
            </Box>

            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
              {stats.map((stat) => (
                <Box key={stat.title}>
                  <Text
                    fontFamily={"heading"}
                    fontSize={"3xl"}
                    color={"white"}
                    mb={3}
                  >
                    {stat.title}
                  </Text>
                  <Text fontSize={"xl"} color={"gray.400"}>
                    {stat.content}
                  </Text>
                </Box>
              ))}
            </SimpleGrid>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}

const StatsText = ({ children }) => (
  <Text as={"span"} fontWeight={700} color={"white"}>
    {children}
  </Text>
);

const stats = [
  {
    title: "Telecom Network Design:",
    content: (
      <>
        Our team of seasoned professionals provides expert design solutions for
        Fiber to the Home (FTTH), Fiber to the Building (FTTB), Fiber to the
        Street (FTTS), and Optical Distribution Network (ODN) projects. We craft
        efficient and reliable network designs that form the backbone of modern
        connectivity.
      </>
    ),
  },
  {
    title: "Survey and Planning:",
    content: (
      <>
        Brontel Ltd offers precision surveying and strategic planning services
        to ensure optimal network performance. We meticulously assess the lay of
        the land, enabling us to strategize and design networks that meet the
        demands of the future.
      </>
    ),
  },
  {
    title: "Optimization:",
    content: (
      <>
        Efficiency is at the heart of what we do. We fine-tune networks,
        maximizing their performance, and ensuring they function seamlessly. Our
        optimization solutions are the key to achieving superior network
        performance while minimizing costs.
      </>
    ),
  },
  {
    title: "Documentation & Network Audits:",
    content: (
      <>
        Accurate and comprehensive documentation is essential for the
        maintenance and growth of any network. Brontel Ltd provides meticulous
        documentation services, ensuring that network records are up-to-date and
        easily accessible, helping you make informed decisions.
        <br />
        To guarantee network efficiency, security, and overall performance,
        Brontel Ltd conducts in-depth network audits. Our experts analyze your
        network from every angle, identifying areas for improvement and
        enhancement.
      </>
    ),
  },
];
