import "./App.css";
import { Box, ChakraProvider } from "@chakra-ui/react";
import Headerbar from "./components/Headerbar";
import NavBar from "./components/NavBar";
import HeroSection from "./components/HeroSection";
import Aboutus from "./components/Aboutus";
import Services from "./components/Services";
import ExpertService from "./components/ExpertService";
import Footer from "./components/Footer";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function Home() {
  return (
    <>
      <HeroSection />
      <Aboutus />
      <Services />
      <Box backgroundColor="#edf7f5">
        <ExpertService />
      </Box>
    </>
  );
}

export default Home;
