import { Box, Divider, Heading, Link, Text, VStack } from "@chakra-ui/react";
import { Link as RouterLink, useNavigate } from "react-router-dom";

function AccordionLinks({ services }) {
  const navigate = useNavigate();

  return (
    <VStack spacing={2} align="stretch" color="blue.300">
      <Text fontSize="2xl" fontWeight="bold" style={{ marginBottom: "2rem" }}>
        Our other Services
      </Text>
      {services.map((service, index) => (
        <Box key={index}>
          <Divider />
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate(
                `../services/${service.name.toLowerCase()?.replace(/ /g, "-")}`
              );
              navigate(0);
            }}
            fontSize="lg"
          >
            {service.name}
          </span>
        </Box>
      ))}
    </VStack>
  );
}

export default AccordionLinks;
