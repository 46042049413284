"use client";

import {
  Flex,
  Circle,
  Box,
  Image,
  Badge,
  useColorModeValue,
  Icon,
  chakra,
  Tooltip,
} from "@chakra-ui/react";
import { useState } from "react";
import { BsStar, BsStarFill, BsStarHalf } from "react-icons/bs";
import { FiShoppingCart } from "react-icons/fi";

const data = {
  isNew: true,
  imageURL:
    "https://images.unsplash.com/photo-1572635196237-14b3f281503f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=4600&q=80",
  name: "Wayfarer Classic Wayfarer Classic Wayfarer Classic dfsdf",
  price: 4.5,
  rating: 4.2,
  numReviews: 34,
};

function ProductAddToCart({ name, imageUrl }) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <Box
      bg={useColorModeValue("white", "gray.800")}
      maxW="sm"
      borderWidth="1px"
      rounded="lg"
      shadow="lg"
      position="relative"
      display="flex"
      flexDir="column"
      overflow="hidden"
    >
      <Image
        src={imageUrl ?? data.imageURL}
        alt={`Picture of ${name}`}
        roundedTop="lg"
        transition="transform 0.3s" // Add a transition for smoother zoom effect
        transform={isHovered ? "scale(1.05)" : "scale(1)"} // Zoom in on hover
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        position="relative"
        width="sm"
        height={{ base: "14rem", md: "17rem" }}
        objectFit="cover"
        objectPosition="center"
      />
      <Box
        fontSize={{ base: "sm", md: "2xl" }}
        fontWeight="semibold"
        as="h4"
        lineHeight="tight"
        isTruncated
        color="white"
        position="absolute"
        flex="1"
        overflow="hidden"
        maxW="100%"
        wordWrap="break-word"
        whiteSpace="normal"
        p="1rem"
        bottom="1rem"
        left="1rem"
        textShadow="2px 2px 4px rgba(0, 0, 0, 0.5)"
      >
        {name ?? ""}
      </Box>
    </Box>
  );
}

export default ProductAddToCart;
