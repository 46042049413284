import "./App.css";
import { Box, ChakraProvider, IconButton } from "@chakra-ui/react";
import Headerbar from "./components/Headerbar";
import NavBar from "./components/NavBar";

import Footer from "./components/Footer";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  BrowserRouter,
} from "react-router-dom";
import Home from "./Home";
import ServiceDetail from "./components/ServiceDetail";
import Contactus from "./components/Contactus";
import { useEffect, useState } from "react";
import { FaArrowUp } from "react-icons/fa";

function App() {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    const isVisible = currentScrollPos > 100;
    setIsVisible(isVisible);
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <BrowserRouter>
      <ChakraProvider>
        <Headerbar />
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contactus />} />
          <Route path="/services/:id" element={<ServiceDetail />} />
        </Routes>
        .
        <Footer />
        <IconButton
          position="fixed"
          bottom="4"
          right="4"
          visibility={isVisible ? "visible" : "hidden"}
          icon={<FaArrowUp />}
          onClick={handleScrollToTop}
          bg="gray.600"
          color="white"
          _hover={{ bg: "gray.700" }}
          _active={{ bg: "gray.800" }}
        />
      </ChakraProvider>
    </BrowserRouter>
  );
}

export default App;
