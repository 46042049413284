const services = [
  {
    name: "Telecom Network Design",
    imageUrl: "/images/1.jpg",
  },
  {
    name: "Survey and Planning",
    imageUrl: "/images/2.jpg",
  },
  {
    name: "Optimization",
    imageUrl: "/images/3.jpg",
  },
  {
    name: "Documentation",
    imageUrl: "/images/4.jpg",
  },
  {
    name: "Network Audits",
    imageUrl: "/images/5.jpg",
  },
  {
    name: "Emergency repairs",
    imageUrl: "/images/6.jpg",
  },
  {
    name: "Network Installation",
    imageUrl: "/images/7.jpg",
  },
  {
    name: "Cable Recovery",
    imageUrl: "/images/8.jpg",
  },
  {
    name: "Fibre Splicing",
    imageUrl: "/images/9.jpg",
  },
];
export default services;
