import {
  Box,
  Container,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Text,
  flexbox,
  useStatStyles,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import AccordionLinks from "./AccordionLinks";
import { useParams } from "react-router-dom";
import services from "../subComponents/serviceList";

const ServiceDetail = () => {
  const [selectedService, setSelectedService] = useState();
  const { id } = useParams();

  const servicesList = services.map((service, index) => ({
    name: service.name,
    id: service.name.toLowerCase().replace(/\s/g, "-"),
    imageUrl: service.imageUrl,
    textBlocks: generateTextBlocks(service.name),
  }));

  function generateTextBlocks(serviceName) {
    let textBlocks = [];

    if (serviceName === "Telecom Network Design") {
      textBlocks = [
        `Our Telecom Network Design services are where innovation meets expertise. With years of industry experience and a team of dedicated professionals, Brontel Limited takes pride in designing network solutions that are not just reliable and efficient but also future-proof. We believe in engineering networks that stand the test of time, capable of handling emerging technologies and accommodating your growing needs.`,
        `Our in-depth network analysis, meticulous planning, and advanced technology integration ensure that your network is not just a network; it's a strategic asset. We collaborate closely with you, aligning your network design with your business goals and ensuring that every component is optimized for performance and cost-effectiveness.`,
        `Our extensive experience in Telecom Network Design allows us to tackle projects of all scales and complexities. Whether it's designing telecommunications networks for industries or communities, we have a proven track record of delivering solutions that exceed expectations. With Brontel Limited, your network design is in safe hands, engineered for excellence and designed for the future.`,
        `When you choose Brontel Limited for your Telecom Network Design needs, you're choosing a partner that brings innovation, reliability, and dedication to the table. Your connectivity is our mission, and we stand committed to engineering solutions that define success.`,
      ];
    } else if (serviceName === "Survey and Planning") {
      textBlocks = [
        `Our Survey and Planning services are the heart of every successful project, and at Brontel Limited, we believe in laying strong foundations. We combine meticulous surveying with comprehensive planning, forming the bedrock of your project's success.`,
        `Utilizing state-of-the-art tools and methodologies, our expert team conducts thorough surveys, leaving no stone unturned. We collect data that serves as the building blocks of your project's strategic planning. Collaboration is key, and we work closely with you to ensure that your project aligns with your objectives and strategic goals.`,
        `When you opt for our Survey and Planning services, you're not just getting a project plan; you're getting a roadmap to success. Brontel Limited empowers you with data-driven decisions, detailed strategic planning, and optimized project success. With us, your project's success is meticulously engineered.`,
      ];
    } else if (serviceName === "Optimization") {
      textBlocks = [
        `Optimization is the cornerstone of operational excellence, and Brontel Limited is here to unlock your full potential. Our Optimization services are more than just a solution; they're a transformation. We delve deep into your systems to identify areas for improvement and implement strategic optimizations.`,
        `Our team harnesses the latest industry knowledge and cutting-edge tools to fine-tune your processes. The result? Reduced costs, improved productivity, and a competitive edge in your industry. With Brontel Limited, you're not just optimizing your processes; you're transforming your operations for a more prosperous future.`,
        `When you invest in Brontel Limited's Optimization services, you're investing in a future of streamlined operations and enhanced productivity. Your success is our mission, and we're committed to turning your vision into operational excellence.`,
      ];
    } else if (serviceName === "Documentation") {
      textBlocks = [
        `Documentation isn't just paperwork; it's the lifeblood of well-organized projects. Brontel Limited's Documentation services ensure that your projects are well-documented, providing you with a comprehensive resource throughout your project's lifecycle.`,
        `Our team meticulously records every aspect of your project, ensuring that you have easy access to crucial information when you need it most. Our documentation is detailed, organized, and user-friendly, providing you with a valuable asset.`,
        `Embrace Brontel Limited's Documentation services, and gain the confidence that comes with having a well-documented and organized project at your fingertips. Your projects, your way - that's the promise of our Documentation services.`,
      ];
    } else if (serviceName === "Network Audits") {
      textBlocks = [
        `Network Audits are your insurance policy against vulnerabilities and inefficiencies in your network infrastructure. At Brontel Limited, we conduct in-depth audits to identify weak points and opportunities for improvement.`,
        `Our expert audit team utilizes advanced tools and methodologies to assess the integrity and performance of your network. The result is a network that's not just optimized for performance but also secured for reliability.`,
        `Choose Brontel Limited's Network Audits, and you can rest easy, knowing that your network is engineered for success. We're not just auditors; we're your partners in network excellence.`,
      ];
    } else if (serviceName === "Network Installation") {
      textBlocks = [
        `Network Installation isn't just about setup; it's about building connections, ensuring reliability, and achieving operational excellence. At Brontel Limited, we take care of every aspect of the installation process, from planning to setup, configuration, and testing.`,
        `Our team's expertise is a guarantee of a reliable, efficient, and customized network installation. We don't just set up networks; we craft solutions that fit your specific requirements and future growth.`,
        `Choose Brontel Limited's Network Installation services for a hassle-free, expertly executed network setup that's engineered for success. Your connectivity, our dedication - that's the promise we deliver on.`,
      ];
    } else if (serviceName === "Cable Recovery") {
      textBlocks = [
        `Cable Recovery is a responsible choice that ensures retired cables don't end up as waste. At Brontel Limited, we recover valuable materials and resources through safe and sustainable methods.`,
        `Our process involves responsible disposal and recycling, reducing environmental impact and contributing to a greener planet. We're dedicated to making old and unused cables contribute to a cleaner environment.`,
        `By choosing Brontel Limited's Cable Recovery services, you're not just making a sustainable choice; you're actively participating in a movement towards a cleaner, more responsible world. Be a part of the solution with Cable Recovery.`,
      ];
    } else if (serviceName === "Fibre Splicing") {
      textBlocks = [
        `Fibre Splicing is the backbone of high-speed data networks over long distances. At Brontel Limited, our Fibre Splicing services ensure a seamless and efficient data network, free from disruptions and bottlenecks.`,
        `Our experienced team uses cutting-edge equipment and techniques to splice fibres with precision, guaranteeing the reliability and integrity of your data network. With Brontel Limited, you're not just connecting fibres; you're connecting to an uninterrupted world of data.`,
        `Opt for Brontel Limited's Fibre Splicing services for a rapid, cost-effective, and environmentally friendly solution for your fibre optic cable needs. We don't just connect fibres; we connect you to an uninterrupted world of data.`,
      ];
    } else if (serviceName === "Emergency repairs") {
      textBlocks = [
        `At Brontel Limited, we understand that emergencies don't wait. When your communication infrastructure is in critical need of repair, you need a partner who responds swiftly and effectively. Our Emergency Repairs service is designed to be your reliable lifeline in times of crisis.`,
        `Our team of experienced technicians is available around the clock, ready to mobilize at a moment's notice. We're well-equipped with the latest tools and technology, ensuring that we can address a wide range of emergency repair scenarios, from network failures to cable damage.`,
        `Our rapid response, coupled with our deep industry knowledge, means that we can quickly diagnose the issue and implement effective solutions. We prioritize minimizing downtime and restoring connectivity with the least disruption to your operations.`,
        `Brontel Limited has a proven track record of handling emergency repairs for a diverse range of clients, from businesses to communities. We've seen it all and resolved it all. When you choose our Emergency Repairs service, you're choosing a partner who can handle the most challenging situations.`,
        `Your network's reliability and your peace of mind are our top priorities. With Brontel Limited, you can rest assured that you have a partner who is prepared and committed to resolving emergencies with speed, expertise, and care.`,
      ];
    }
    // Add more conditions for other services

    return textBlocks;
  }

  useEffect(() => {
    console.log("🚀 ~ file: ServiceDetail.jsx:44 ~ useEffect ~ serviceId:", id);
    if (id) {
      const activeItem = servicesList.find((element) => {
        return element.name.toLowerCase().replace(/ /g, "-") === id;
      });
      console.log(
        "🚀 ~ file: ServiceDetail.jsx:47 ~ activeItem ~ activeItem:",
        activeItem
      );
      setSelectedService(activeItem);
    }
  }, []);

  return (
    <div>
      <Box>
        <Image
          src={selectedService?.imageUrl}
          alt=""
          width="100%"
          height={{ base: "15rem", md: "25rem" }}
          objectFit="cover"
          objectPosition="center"
          mb="5rem"
        />
        <Container maxW={"80%"} zIndex={10} position={"relative"}>
          <Heading my="4rem">{selectedService?.name}</Heading>
          <Box display="flex" flexDir="row" gap="5rem" mb="5rem">
            <Box flex="3">
              {selectedService?.textBlocks?.map((item, index) => (
                <Text mb="0.4rem">{item}</Text>
              ))}
            </Box>
            <Box flex="1">
              <AccordionLinks services={servicesList} />
            </Box>
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default ServiceDetail;
