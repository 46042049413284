"use client";

import { ReactNode } from "react";
import {
  Stack,
  Container,
  Box,
  Flex,
  Text,
  Heading,
  SimpleGrid,
} from "@chakra-ui/react";
import Servicescard from "../subComponents/Servicescard";
import services from "../subComponents/serviceList";
import { useNavigate } from "react-router-dom";
export default function StatsGridWithImage() {
  const navigate = useNavigate();
  return (
    <Box position={"relative"} id="services">
      <Container maxW={"80%"} zIndex={10} position={"relative"}>
        <Stack direction={{ base: "column", lg: "row" }}>
          <Stack
            flex={1}
            color={"gray.400"}
            justify={{ lg: "center" }}
            py={{ base: 4, md: 20 }}
          >
            <Box mb={{ base: 8, md: 20 }}>
              <Heading
                color={"gray.600"}
                mb={5}
                fontSize={{ base: "3xl", md: "5xl" }}
              >
                Services
              </Heading>

              <Flex fontSize={"xl"} color={"gray.400"} gap="1.5rem" mb="5rem">
                <Box width="100%">
                  {" "}
                  At Brontel Ltd, we are dedicated to transforming the
                  telecommunication landscape through our expertise in Telecom
                  Network Design, Survey and Planning, Optimization,
                  Documentation, and Network Audits.
                </Box>
              </Flex>
              <br />
              <SimpleGrid
                columns={{ base: "1", md: "3" }}
                spacing={4}
                position="relative"
              >
                {services.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      navigate(
                        `/services/${item.name
                          .toLocaleLowerCase()
                          .replace(/ /g, "-")}`
                      );
                      navigate(0);
                    }}
                  >
                    <Servicescard imageUrl={item.imageUrl} name={item.name} />
                  </div>
                ))}
              </SimpleGrid>
            </Box>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}
