import React from "react";
import { Flex, Image, Text } from "@chakra-ui/react";
import { EmailIcon } from "@chakra-ui/icons";
import { MdLocationOn, MdPhone } from "react-icons/md";

function Headerbar() {
  return (
    <Flex
      direction={{ base: "column", md: "row" }}
      gap="1rem"
      justifyContent="flex-end"
      align="end"
      pr="2rem"
      p="1.5rem"
      color="#4356a6"
      backgroundColor="#f2f2f2"
    >
      <Flex gap="0.5rem" align="center">
        <EmailIcon />
        <Text className="email">info@brontel.co.ke</Text>
      </Flex>
      <Flex gap="0.5rem" align="center">
        <MdLocationOn />
        <Text className="email">Shelter Afrique, Longonot Road, Nairobi.</Text>
      </Flex>
      <Flex gap="0.5rem" align="center">
        <MdPhone />
        <Text className="email">+254718026816 </Text>
      </Flex>
    </Flex>
  );
}

export default Headerbar;
